import { useState } from "react";

import IntroductionView from "../../shared/components/IntroductionView";
import { PrimaryButton } from "../../shared/components/Button";
import {
  StyledLoaderCenterContainer,
  StyledLoader,
} from "../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../external/EddaXcomp/src/constants";
import {
  StyledLoadingContainer,
  StyledParagraph,
} from "../dashboard/StyledDashboard";
import useAdventureStory from "./useAdventureStory";

const buttonStyles = {
  width: "237px",
  height: "50px",
  boxShadow: "none",
  animationDelay: "4s",
};

export const AdventureStoryView = () => {
  const {
    posts,
    markersToComplete,
    isLastSlide,
    isLastProblem,
    lastSlideVisited,
    buttonText,
    onStartAdventureCard,
    onSlideChange,
  } = useAdventureStory();

  const [disabled, setDisabled] = useState(false);

  if (!posts.length) {
    return (
      <StyledLoadingContainer>
        <StyledParagraph>Laddar innehåll!</StyledParagraph>
        <StyledLoaderCenterContainer>
          <StyledLoader size={LARGE} />
        </StyledLoaderCenterContainer>
      </StyledLoadingContainer>
    );
  }

  const onClick = () => {
    setDisabled(true);
    onStartAdventureCard();
  };

  return (
    <IntroductionView
      slides={posts}
      onSlideChange={onSlideChange}
      isLastProblem={isLastProblem}
      markersToComplete={markersToComplete}
    >
      {(isLastSlide || lastSlideVisited) && (
        <PrimaryButton
          disabled={disabled}
          animation="pulse"
          label={buttonText}
          overrideStyles={buttonStyles}
          onClick={onClick}
        />
      )}
    </IntroductionView>
  );
};
