import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterActions } from "../../shared";
import AdventureCarousel from "../../shared/components/AdventureCarousel/AdventureCarousel";
import { Item, Items } from "../../shared/components/AdventureCarousel/types";
import AdventurePickerList from "../../shared/components/AdventurePickerList/AdventurePickerList";
import { AudioComponent } from "../../shared/components/Audio";
import { HomeButton } from "../../shared/components/Button/HomeButton";
import { PRIMARY_SCHOOL } from "../../shared/constants";
import { selectTheme } from "../profile/api/selectors";
import { ATTIC_ROUTE } from "../routes";
import { getAdventureCard } from "./api/actions";
import { selectAdventureGroups, selectAdventures } from "./api/selectors";
import { Adventures } from "./api/types";
import FadeIn from "./FadeIn";
import {
  StyledBackground,
  StyledAdventurePickerContainer,
  StyledMonitor,
  StyledMonitorContainer,
  StyledGoBackButton,
  StyledButton,
  StyledButtonText,
  StyledButtonContainer,
  StyledGoBackArrow,
  StyledHomeButtonWrapper,
  StyledAdventureLabel,
  StyledAdventureAudioContainer,
} from "./StyledAdventurePicker";
import { useWindowWidth } from "./useWindowWidth";

const TABLET_BREAKPOINT = 880;
const audioButtonStyles = {
  width: "35px",
  height: "35px",
  background: "black",
  boxShadow: "none",
  border: "2px solid white",
};

const audioColor = {
  active: "white",
  notActive: "white",
};

export const AdventurePickerView = () => {
  const [selectedAdventureGroup, setSelectedAdventureGroup] =
    useState<Item | null>(null);

  const windowWidth = useWindowWidth();

  const [selectedAdventure, setSelectedAdventure] = useState<Item | null>(null);
  const [view, setView] =
    useState<"adventureGroups" | "adventure">("adventureGroups");
  const [showAboutStory, setShowAboutStory] = useState(false);
  const adventureGroups = useSelector(selectAdventureGroups);
  const adventures = useSelector(selectAdventures);
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  const onGoToAdventureGroup = () => {
    setView("adventure");
  };

  const onSelectAdventureGroup = (item: Item) => {
    setSelectedAdventureGroup(item);
  };

  const onSelectAdventure = (item: Item) => {
    setSelectedAdventure(item);
  };

  const getAdventurePickerTitle = () => {
    if (theme === "middle-school") {
      return showAboutStory && selectedAdventureGroup
        ? `${selectedAdventureGroup.title}: fakta`
        : "VÄLJ TIDSÅLDER";
    }
    return "VÄLJ PLANET";
  };

  const onGoToAdventure = () => {
    if (selectedAdventureGroup && selectedAdventure) {
      setDisabled(true);
      dispatch(
        getAdventureCard(selectedAdventureGroup.id, selectedAdventure.id)
      );
    }
  };

  const convertToItems = (arr: Adventures): Items => {
    arr.sort((a, b) => a.weight - b.weight);

    return arr.map((a) => ({
      title: a.title,
      id: a.id,
      description: a.description,
      description_audio_url: a.description_audio_url,
      started: a.started,
      completed: a.completed,
      locked: a.locked,
      image_url: a.image_url,
      text: a.text,
      weight: a.weight,
      text_audio_url: a.text_audio_url,
      rewards_to_complete: a.rewards_to_complete,
    }));
  };

  const onGoBack = () => {
    if (view === "adventure") {
      setSelectedAdventureGroup(null);
      setSelectedAdventure(null);
      setView("adventureGroups");
      return;
    }
    if (showAboutStory) {
      setShowAboutStory(false);
      return;
    }
    dispatch(RouterActions.push(ATTIC_ROUTE));
  };

  const desktop = !windowWidth || windowWidth >= TABLET_BREAKPOINT;
  return (
    <StyledAdventurePickerContainer>
      <StyledHomeButtonWrapper>
        <HomeButton />
      </StyledHomeButtonWrapper>
      <StyledBackground primarySchool={theme === PRIMARY_SCHOOL}>
        <StyledMonitorContainer desktop={+desktop}>
          <StyledMonitor
            desktop={+desktop}
            primarySchool={theme === PRIMARY_SCHOOL}
          >
            <FadeIn
              fadeKey="goback"
              isIn={view === "adventure" || view === "adventureGroups"}
            >
              <>
                <StyledGoBackButton
                  aria-label="gå tillbaka"
                  desktop={+desktop}
                  onClick={onGoBack}
                />
                <StyledGoBackArrow desktop={+desktop} onClick={onGoBack} />
              </>
            </FadeIn>
            {showAboutStory && selectedAdventureGroup && (
              <StyledAdventureAudioContainer>
                <AudioComponent
                  audioColor={audioColor}
                  audioUrl={selectedAdventureGroup.description_audio_url || ""}
                  styles={audioButtonStyles}
                />
              </StyledAdventureAudioContainer>
            )}
            <FadeIn
              fadeKey="label"
              isIn={view === "adventure" || view === "adventureGroups"}
            >
              <StyledAdventureLabel>
                {view === "adventureGroups"
                  ? getAdventurePickerTitle()
                  : `${
                      selectedAdventureGroup &&
                      selectedAdventureGroup.title.toUpperCase()
                    }: VÄLJ ÄVENTYR`}
              </StyledAdventureLabel>
            </FadeIn>
            {view === "adventureGroups" && (
              <AdventureCarousel
                setSelected={onSelectAdventureGroup}
                selected={selectedAdventureGroup}
                theme={theme}
                showDetails={showAboutStory}
                items={convertToItems(adventureGroups)}
                viewSize={desktop ? "desktop" : "tablet"}
              />
            )}
            {view === "adventure" && (
              <>
                {theme === "primary-school" ? (
                  <AdventureCarousel
                    setSelected={onSelectAdventure}
                    selected={selectedAdventure}
                    theme={theme}
                    showDetails={false}
                    items={convertToItems(
                      adventures.filter(
                        (a) => a.parent_id === selectedAdventureGroup?.id
                      )
                    )}
                    viewSize={desktop ? "desktop" : "tablet"}
                  />
                ) : (
                  <AdventurePickerList
                    setSelected={onSelectAdventure}
                    selected={selectedAdventure}
                    selectedAdventureGroup={selectedAdventureGroup}
                    items={convertToItems(
                      adventures.filter(
                        (a) => a.parent_id === selectedAdventureGroup?.id
                      )
                    )}
                    viewSize={desktop ? "desktop" : "tablet"}
                  />
                )}
              </>
            )}
            <StyledButtonContainer>
              {!showAboutStory && (
                <>
                  <FadeIn
                    fadeKey="about"
                    isIn={
                      view === "adventureGroups" &&
                      theme === "middle-school" &&
                      Boolean(selectedAdventureGroup)
                    }
                  >
                    <StyledButton
                      onClick={() => setShowAboutStory(!showAboutStory)}
                      aria-label="Fakta"
                    >
                      <StyledButtonText>FAKTA</StyledButtonText>
                    </StyledButton>
                  </FadeIn>

                  <FadeIn
                    fadeKey={view}
                    isIn={
                      view === "adventureGroups" &&
                      Boolean(selectedAdventureGroup)
                    }
                  >
                    <StyledButton
                      aria-label="välj äventyrsgrupp"
                      onClick={onGoToAdventureGroup}
                    >
                      <StyledButtonText>
                        {`ÅK TILL ${
                          selectedAdventureGroup && selectedAdventureGroup.title
                        }`}
                      </StyledButtonText>
                    </StyledButton>
                  </FadeIn>
                </>
              )}

              <FadeIn
                fadeKey="continue"
                isIn={view === "adventure" && Boolean(selectedAdventure)}
              >
                <StyledButton
                  disabled={disabled}
                  onClick={onGoToAdventure}
                  aria-label="starta äventyr"
                >
                  <StyledButtonText>STARTA ÄVENTYR</StyledButtonText>
                </StyledButton>
              </FadeIn>
            </StyledButtonContainer>
          </StyledMonitor>
        </StyledMonitorContainer>
      </StyledBackground>
    </StyledAdventurePickerContainer>
  );
};
